// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ea18b141-644a-445b-ba8b-0b64838ddc71",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_sqH1wz8L0",
    "aws_user_pools_web_client_id": "3ql6tqkusadu81b4a71l9qm8pv",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://al2y2gow4zgabo2mfnledbm6hu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "flume-web-app-20191015134547-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3ebdnr4zoonow.cloudfront.net",
    "aws_user_files_s3_bucket": "flume-web-app9527112a5fcb423ebdf18c86e35cf684-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
